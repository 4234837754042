<template>
  <v-app-bar app clipped-left :flat="!$vuetify.breakpoint.smAndDown" color="white">
    <v-app-bar-nav-icon
      v-if="!['Login'].includes($route.name)"
      @click.stop="$emit('toggle-mobiledrawer')"
      class="hidden-md-and-up"
    />
    <v-toolbar-title class="headline noselect pl-1">
      <router-link to="/" tag="span" style="cursor: pointer;">
        <span class="primary--text">ΛLER</span>
        <span class="font-weight-light secondary--text">TEL/</span>
      </router-link>
      <span class="subtitle-1 grey--text hidden-sm-and-down">&nbsp;by Banque Edel</span>
    </v-toolbar-title>
    <v-spacer />
    <div v-if="!['Login'].includes($route.name)">
      <NavShopSelect v-if="$vuetify.breakpoint.mdAndUp" />

      <ProfilPopup v-if="$vuetify.breakpoint.mdAndUp" />
    </div>
  </v-app-bar>
</template>

<script>
export default {
  components: {
    NavShopSelect: () => import("@/components/Layout/NavBarShopSelect"),
    ProfilPopup: () => import("@/components/User/ProfilPopup"),
  },
};
</script>
